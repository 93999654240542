import React, { useEffect, useState } from 'react';

const beautyStyles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    background: '#f7f7f7',
    borderRadius: '5px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: '14px',
    marginBottom: '20px',
    textAlign: 'center',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    fontSize: '16px',
  },
  button: {
    width: '100%',
    padding: '10px',
    background: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  successMessage: {
    color: 'green',
    textAlign: 'center',
    fontSize: '18px',
  },
};

function DynamicPage() {
  const [jsUrl, setJsUrl] = useState('');
  const [element, setElement] = useState('');
  const [pageCreated, setPageCreated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (jsUrl && element) {
      createPage()
    }
  }, [jsUrl, element])

  useEffect(() => {
    // Function to parse query parameters from the URL
    const parseQueryParameters = (queryString) => {
      const params = new URLSearchParams(queryString);
      const payloadParam = params.get('payload');

      const localJsLink = localStorage.getItem('wpi-preview-jsLink')
      const localComponent = localStorage.getItem('wpi-preview-component')
      let payloadData = {}
      try {
        if (payloadParam) {
          payloadData = JSON.parse(decodeURIComponent(payloadParam));
        }
        // Update state based on the parsed payloadData
        if (payloadData.jsLink) {
          localStorage.setItem('wpi-preview-jsLink', payloadData.jsLink)
          setJsUrl(payloadData.jsLink);
        } else {
          setJsUrl(localJsLink)
        }
        if (payloadData.component) {
          localStorage.setItem('wpi-preview-component', payloadData.component)
          setElement(payloadData.component);
        } else {
          setElement(localComponent)
        }
      } catch (error) {
        console.error('Error parsing payload:', error);
        setJsUrl(localJsLink)
        setElement(localComponent)
      }
    };

    // Call the parseQueryParameters function with the current URL's search string
    parseQueryParameters(window.location.search);
    // Rest of your useEffect logic...
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  const createPage = () => {
    // Load external JS
    if (jsUrl) {
      setLoading(true); // Set loading state to true while loading JS
      const scriptTag = document.createElement('script');
      scriptTag.src = jsUrl;
      scriptTag.onload = () => {
        setLoading(false); // Set loading state to false after JS is loaded
        // Create and append the specified HTML element to the body
        if (element) {
          // Create a new DOMParser and append the parsed element
          const parser = new DOMParser();
          try {
            const parsedElement = parser.parseFromString(element, 'text/html').body.firstChild;
            document.body.appendChild(parsedElement);
          } catch (error) {
            console.error('Error parsing element:', error);
          }
        }
        setPageCreated(true); // Set pageCreated to true to hide the form
      };
      document.head.appendChild(scriptTag);
    } else {
      // If no JS URL is provided, create the element immediately
      if (element) {
        const newElement = document.createElement(element);
        document.body.appendChild(newElement);
      }
      setPageCreated(true); // Set pageCreated to true to hide the form
    }
  };

  if (loading) return <p>Loading..</p>
  if (!pageCreated) return <p>Failed to load page, please contact support</p>
  return <></>
}

export default DynamicPage;
